// npm i socket.io-client
import { io } from 'socket.io-client'
import { toast } from 'vue3-toastify'

const socket = io(import.meta.env.VITE_SOCKET_URL, {
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 5000,
  extraHeaders: {
    'authorization': `Bearer ${localStorage.getItem('token')}`,
    'client-ref': localStorage.getItem('client_ref') ?? crypto.randomUUID()
  }
})

socket.on('connect', () => {
  console.log('Connected to socket server')
})

socket.on('disconnect', () => {
  console.log('Disconnected from socket server')
})

socket.on('error', (error) => {
  console.error('Socket error:', error)
})

socket.on('notification', (data) => {
  toast(data.message, {
    "position": "bottom-center",
    "theme": "auto",
    "type": data.label,
    "pauseOnFocusLoss": false,
    "closeOnClick": false,
    "hideProgressBar": true
  })
});

export default socket
